import {environment} from '../../../../environments/environment';
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable, forkJoin} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Action} from '../../../model/action';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ActionService} from '../../../core/action.service';
import {TaskService} from '../../../core/task.service';
import {PrescrizioniService} from '../../../core/prescrizioni.service';
import {UtilsService} from '../../../core/utils.service';
import {Task} from '../../../model/query/task';
import {TaskDdt} from '../../../model/query/task-ddt';
import {TaskDocumento} from '../../../model/query/task-documento';
import {PrescrizioneVentilatoreDto} from '../../../model/query/prescrizione-ventilatore-dto';
import {ChiusuraTaskDto} from '../../../model/command/chiusura-task-dto';
import {ChiusuraTaskConsegnaDto} from '../../../model/command/chiusura-task-consegna-dto';
import {ChiusuraTaskCollaudoDto} from '../../../model/command/chiusura-task-collaudo-dto';
import {ChiusuraTaskAccessoOrdinarioDto} from '../../../model/command/chiusura-task-accesso-ordinario-dto';
import {ChiusuraTaskAccessoStraordinarioDto} from '../../../model/command/chiusura-task-accesso-straordinario-dto';
import {ChiusuraTaskFollowupDto} from '../../../model/command/chiusura-task-followup-dto';
import {ChiusuraTaskRitiroDispositivoDto} from '../../../model/command/chiusura-task-ritiro-dispositivo-dto';
import {ChiusuraTaskVerificaElettricaDto} from '../../../model/command/chiusura-task-verifica-elettrica-dto';
import {TaskDocumentoCommand} from '../../../model/command/task-documento-command';
import {PrescrizioneActionsService} from '../../../core/prescrizione-actions.service';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html'
})
export class TaskDetailComponent implements OnInit {
    private storageBasePath: string = environment.storageUrl;
    
    @ViewChild('fileInputDocumentoTask', { static: false }) fileInputDocumentoTask: ElementRef;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    tipo: string;
    title: string;
    task: Task;
    documenti = new Array<TaskDocumento>();
    ddt: Array<TaskDdt>;
    prescrizioneVentilatore: PrescrizioneVentilatoreDto;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    signatureNeeded: boolean = false;
    isModalSignatureOpen = false;
    firma: string;
    documento: File;
    ddtToSign: string;
    firmaTecnico: File = null;
    firmaAssistito: File = null;
    firmaCaregiver: File = null;
    firmaTecnicoImg: string = '';
    firmaAssistitoImg: string = '';
    firmaCaregiverImg: string = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public taskService: TaskService,
        private prescrizioniService: PrescrizioniService,
        private prescrizioneActionsService: PrescrizioneActionsService,
        private utilsService: UtilsService,
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
        public globals: Globals,
        public actionService: ActionService,
        private dialog: DialogService
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.tipo = this.route.snapshot.queryParamMap.get('tipo');
        this.title = this.utilsService.getTipiTaskDescription()[this.tipo];
        this.createForm();
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.action) {
                this.action = params.action;
            }
            this.taskService.getTask(this.id, this.tipo).subscribe((task) => {
                this.task = task;
                if (task.dataPrevista > new Date()) {
                    this.dialog.info({
                        title: 'Info',
                        content: 'ATTENZIONE! La data prevista di questa attività è nel futuro, non potrai completarla',
                    }).subscribe(() => {});
                }
                this.signatureNeeded = task.tipo === this.globals.TIPO_TASK_CONSEGNA_PAZIENTE || 
                    task.tipo === this.globals.TIPO_TASK_COLLAUDO || 
                    task.tipo === this.globals.TIPO_TASK_ACCESSO_ORDINARIO || 
                    task.tipo === this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO || 
                    task.tipo === this.globals.TIPO_TASK_RITIRO_DISPOSITIVO;
                    
                const requestList: Array<Observable<Array<any>>> = [
                    this.taskService.getDocumenti('documenti', this.id),
                    this.taskService.getDocumenti('ddt', this.id)
                ];

                forkJoin(requestList).subscribe(([documenti, ddt]) => {
                    this.documenti = documenti;
                    this.ddt = ddt;
                    if (task.tipo === this.globals.TIPO_TASK_COLLAUDO) {
                        this.prescrizioniService.getPrescrizioneVentilatore(task.prescrizioneVentilatore.id.toString()).subscribe(dto => {
                            this.prescrizioneVentilatore = dto;
                            this.initializeFormFields();
                        });
                    } else {
                        this.initializeFormFields();
                    }
                });
            });
        });
    }
    
    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();

        let command: ChiusuraTaskDto;

        if (this.tipo === this.globals.TIPO_TASK_CONSEGNA_PAZIENTE || this.tipo === this.globals.TIPO_TASK_COLLAUDO 
            || this.tipo === this.globals.TIPO_TASK_ACCESSO_ORDINARIO || this.tipo === this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO
             || this.tipo === this.globals.TIPO_TASK_RITIRO_DISPOSITIVO || this.tipo === this.globals.TIPO_TASK_VERIFICA_ELETTRICA) {
            this.taskService.getSignaturesUrl(this.id).subscribe(result => {
                let observables: Array<Observable<any>> = new Array<Observable<any>>();

                if (this.firmaTecnico) {
                    observables.push(this.httpClient.put(this.storageBasePath + result.firmaTecnicoUrl, this.firmaTecnico));
                } 
                if (this.firmaAssistito) {
                    observables.push(this.httpClient.put(this.storageBasePath + result.firmaAssistitoUrl, this.firmaAssistito));
                } 
                if (this.firmaCaregiver) {
                    observables.push(this.httpClient.put(this.storageBasePath + result.firmaDelegatoUrl, this.firmaCaregiver));
                } 
                forkJoin(observables).subscribe(() => {
                    if (this.tipo === this.globals.TIPO_TASK_CONSEGNA_PAZIENTE) {
                        command = new ChiusuraTaskConsegnaDto(this.utilsService.fieldToDate(formValue.dataCompletamento), formValue.stato === "2", formValue.note, '', 
                            this.firmaTecnico ? this.firmaTecnico.name : null, this.firmaAssistito ? this.firmaAssistito.name : null, this.firmaCaregiver ? this.firmaCaregiver.name : null,
                            formValue.usaDispositiviAutonomamente, formValue.comprendeIstruzioni, formValue.coadiuvato, formValue.altriDispositivi, formValue.adeguamenti);
                    } else if (this.tipo === this.globals.TIPO_TASK_COLLAUDO) {
                        command = new ChiusuraTaskCollaudoDto(this.utilsService.fieldToDate(formValue.dataCompletamento), formValue.stato === "2", formValue.note, 
                            this.firmaTecnico ? this.firmaTecnico.name : null, this.firmaAssistito ? this.firmaAssistito.name : null, this.firmaCaregiver ? this.firmaCaregiver.name : null,
                            formValue.matricola1, formValue.matricola2, formValue.gruppoContinuita, formValue.esito === '1');
                    } else if (this.tipo === this.globals.TIPO_TASK_ACCESSO_ORDINARIO) {
                        command = new ChiusuraTaskAccessoOrdinarioDto(this.utilsService.fieldToDate(formValue.dataCompletamento), formValue.stato === "2", formValue.note, 
                            this.firmaTecnico ? this.firmaTecnico.name : null, this.firmaAssistito ? this.firmaAssistito.name : null, this.firmaCaregiver ? this.firmaCaregiver.name : null, formValue.oreFunzionamento);
                    } else if (this.tipo === this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO) {
                        command = new ChiusuraTaskAccessoStraordinarioDto(this.utilsService.fieldToDate(formValue.dataCompletamento), formValue.stato === "2", formValue.note, 
                            this.firmaTecnico ? this.firmaTecnico.name : null, this.firmaAssistito ? this.firmaAssistito.name : null, this.firmaCaregiver ? this.firmaCaregiver.name : null, 
                            formValue.oreFunzionamento, formValue.reperibilita, formValue.anomaliaSegnalata, formValue.anomaliaRiscontrata, formValue.intervento, formValue.nuovaMatricola, formValue.risoltoTelefonicamente);
                    } else if (this.tipo === this.globals.TIPO_TASK_RITIRO_DISPOSITIVO) {
                        command = new ChiusuraTaskRitiroDispositivoDto(this.utilsService.fieldToDate(formValue.dataCompletamento), formValue.stato === "2", formValue.note, 
                            this.firmaTecnico ? this.firmaTecnico.name : null, this.firmaAssistito ? this.firmaAssistito.name : null, this.firmaCaregiver ? this.firmaCaregiver.name : null, 
                            formValue.oreFunzionamento, formValue.statoDispositivo, formValue.ritiroConsumabili);
                    } else if (this.tipo === this.globals.TIPO_TASK_VERIFICA_ELETTRICA) {
                        command = new ChiusuraTaskVerificaElettricaDto(this.utilsService.fieldToDate(formValue.dataCompletamento), formValue.stato === "2", formValue.note, 
                            this.firmaTecnico ? this.firmaTecnico.name : null, new Date(), formValue.strumento, formValue.costruttore,
                            formValue.serialNumber, formValue.partiApplicate, formValue.verificaGenerale, formValue.controlloComponenti, formValue.tensioneL1L2, formValue.tensioneL1PE,
                            formValue.tensioneL2PE, formValue.dispersionePI, formValue.dispersionePAPI, formValue.dispersionePN, formValue.dispersionePAPN, formValue.dispersionePISuperato,
                            formValue.dispersionePAPISuperato, formValue.dispersionePNSuperato, formValue.dispersionePAPNSuperato, formValue.correnteAssorbita, formValue.potenzaAssorbita,
                            formValue.risultatoTest);
                    }
                    this.taskService.closeTask(this.id, this.task.tipo, command).subscribe((res) => {
                        this.submitButton = ClrLoadingState.DEFAULT;
                        if (res && res.status == 200) {
                            this.alertMessage = 'Completamento task effettuato!';
                            this.alertClosed = false;
                            this.router.navigate(['/task']);
                        }
                    });
                });
            });
        } else {
            if (this.tipo === this.globals.TIPO_TASK_FOLLOWUP) {
                command = new ChiusuraTaskFollowupDto(this.utilsService.fieldToDate(formValue.dataCompletamento), formValue.stato === "2", formValue.note,
                    formValue.followupPositivo, formValue.chiarimenti, formValue.ulterioreFollowup);
            } else {
                command = new ChiusuraTaskDto(this.utilsService.fieldToDate(formValue.dataCompletamento), formValue.stato === "2", formValue.note);
            }
            this.taskService.closeTask(this.id, this.task.tipo, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (res && res.status == 200) {
                    this.alertMessage = 'Completamento task effettuato!';
                    this.alertClosed = false;
                    this.router.navigate(['/task']);
                }
            });
        }
    }

    createForm(): void {
        let formGroup: any;

        formGroup = {
            azienda: [{value: '', disabled: true}],
            distretto: [{value: '', disabled: true}],
            comune: [{value: '', disabled: true}],
            prescrittore: [{value: '', disabled: true}],
            paziente: [{value: '', disabled: true}],
            indirizzo: [{value: '', disabled: true}],
            delegato: [{value: '', disabled: true}],
            profilo: [{value: '', disabled: true}],
            dispositivo: [{value: '', disabled: true}],
            modalitaVentilatoria: [{value: '', disabled: true}],
            tipoMaschera: [{value: '', disabled: true}],
            modelloMaschera: [{value: '', disabled: true}],
            marcaMaschera: [{value: '', disabled: true}],
            tagliaMaschera: [{value: '', disabled: true}],
            kitMateriali1: [{value: '', disabled: true}],
            kitMateriali2: [{value: '', disabled: true}],
            kitMateriali3: [{value: '', disabled: true}],
            kitMateriali4: [{value: '', disabled: true}],
            incaricato: [{value: '', disabled: true}],
            nonDisalimentabile: [{value: false, disabled: true}],
            dataPrevista: [{value: '', disabled: true}],
            dataCompletamento: ['', [Validators.required]],
            stato: ['', [Validators.required]],
            noteUfficio: [{value: '', disabled: true}],
            note: ''
        };

        if (this.tipo === this.globals.TIPO_TASK_CONSEGNA_PAZIENTE) {
            formGroup.usaDispositiviAutonomamente = false;
            formGroup.comprendeIstruzioni = false;
            formGroup.coadiuvato = false;
            formGroup.altriDispositivi = false;
            formGroup.adeguamenti = '';
        } else if (this.tipo === this.globals.TIPO_TASK_COLLAUDO) {
            formGroup.matricola1 = '';
            formGroup.matricola2 = '';
            formGroup.gruppoContinuita = false;
            formGroup.esito = '1';
        } else if (this.tipo === this.globals.TIPO_TASK_GENERICO) {
            formGroup.descrizione = '';
        } else if (this.tipo === this.globals.TIPO_TASK_ACCESSO_ORDINARIO) {
            formGroup.oreFunzionamento = '';
        } else if (this.tipo === this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO) {
            formGroup.oreFunzionamento = '';
            formGroup.reperibilita = false;
            formGroup.anomaliaSegnalata = '';
            formGroup.anomaliaRiscontrata = '';
            formGroup.intervento = '';
            formGroup.nuovaMatricola = '';
            formGroup.risoltoTelefonicamente = false;
        } else if (this.tipo === this.globals.TIPO_TASK_FOLLOWUP) {
            formGroup.followupPositivo = false;
            formGroup.chiarimenti = false;
            formGroup.ulterioreFollowup = false;
        } else if (this.tipo === this.globals.TIPO_TASK_RITIRO_DISPOSITIVO) {
            formGroup.oreFunzionamento = '';
            formGroup.statoDispositivo = '';
            formGroup.ritiroConsumabili = false;
        } else if (this.tipo === this.globals.TIPO_TASK_VERIFICA_ELETTRICA) {
            formGroup.strumento = ['', [Validators.required]];
            formGroup.costruttore = '';
            formGroup.serialNumber = '';
            formGroup.partiApplicate = ['', [Validators.required]];
            formGroup.verificaGenerale = false;
            formGroup.controlloComponenti = false;
            formGroup.tensioneL1L2 = 0;
            formGroup.tensioneL1PE = 0;
            formGroup.tensioneL2PE = 0;
            formGroup.dispersionePI = 0;
            formGroup.dispersionePAPI = 0;
            formGroup.dispersionePN = 0;
            formGroup.dispersionePAPN = 0;
            formGroup.dispersionePISuperato = false;
            formGroup.dispersionePAPISuperato = false;
            formGroup.dispersionePNSuperato = false;
            formGroup.dispersionePAPNSuperato = false;
            formGroup.correnteAssorbita = 0;
            formGroup.potenzaAssorbita = 0;
            formGroup.risultatoTest = false;
        }
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(): void {
        let formValues;

        formValues = {
            azienda: this.task.prescrizioneVentilatore.prescrizione.paziente.comune.distretto.azienda.nome,
            distretto: this.task.prescrizioneVentilatore.prescrizione.paziente.comune.distretto.nome,
            comune: this.task.prescrizioneVentilatore.prescrizione.paziente.comune.nome,
            prescrittore: this.task.prescrizioneVentilatore.prescrizione.prescrittore.nome + ' ' + this.task.prescrizioneVentilatore.prescrizione.prescrittore.cognome,
            paziente: this.task.prescrizioneVentilatore.prescrizione.paziente.nome + ' ' + this.task.prescrizioneVentilatore.prescrizione.paziente.cognome,
            indirizzo: this.task.prescrizioneVentilatore.prescrizione.paziente.indirizzoConsegna + ' ' + this.task.prescrizioneVentilatore.prescrizione.paziente.numeroCivicoConsegna,
            delegato: this.task.prescrizioneVentilatore.prescrizione.paziente.nomeDelegato + ' ' + this.task.prescrizioneVentilatore.prescrizione.paziente.cognomeDelegato,
            profilo: this.task.prescrizioneVentilatore.profilo.nome,
            dispositivo: this.task.prescrizioneVentilatore.ventilatore.nome,
            modalitaVentilatoria: this.task.prescrizioneVentilatore.modalitaVentilatoria.nome ? this.task.prescrizioneVentilatore.modalitaVentilatoria.nome : '',
            tipoMaschera: this.task.prescrizioneVentilatore.tipoMaschera ? this.task.prescrizioneVentilatore.tipoMaschera : '',
            modelloMaschera: this.task.prescrizioneVentilatore.modelloMaschera ? this.task.prescrizioneVentilatore.modelloMaschera : '',
            marcaMaschera: this.task.prescrizioneVentilatore.marcaMaschera ? this.task.prescrizioneVentilatore.marcaMaschera : '',
            tagliaMaschera: this.task.prescrizioneVentilatore.tagliaMaschera ? this.task.prescrizioneVentilatore.tagliaMaschera : '',
            kitMateriali1: this.task.prescrizioneVentilatore.profilo.kitMateriale.nome ? this.task.prescrizioneVentilatore.profilo.kitMateriale.nome : '',
            kitMateriali2: this.task.prescrizioneVentilatore.profilo.kitMaterialeSelezionabili.nome ? this.task.prescrizioneVentilatore.profilo.kitMaterialeSelezionabili.nome : '',
            kitMateriali3: this.task.prescrizioneVentilatore.profilo.kitMaterialeMedicazione.nome ? this.task.prescrizioneVentilatore.profilo.kitMaterialeMedicazione.nome : '',
            kitMateriali4: this.task.prescrizioneVentilatore.profilo.kitMaterialeMedicazioneSelezionabili.nome ? this.task.prescrizioneVentilatore.profilo.kitMaterialeMedicazioneSelezionabili.nome : '',
            incaricato: this.task.incaricato.nome + ' ' + this.task.incaricato.cognome,
            nonDisalimentabile: this.task.prescrizioneVentilatore.quantita > 1,
            dataPrevista: this.utilsService.dateToField(this.task.dataPrevista),
            dataCompletamento: this.utilsService.dateToField(new Date()),
            stato: '2',
            noteUfficio: this.task.noteUfficio || '',
            note: this.task.note || ''
        };
        if (this.tipo === this.globals.TIPO_TASK_CONSEGNA_PAZIENTE) {
            formValues['usaDispositiviAutonomamente'] = false;
            formValues['comprendeIstruzioni'] = false;
            formValues['coadiuvato'] = false;
            formValues['altriDispositivi'] = false;
            formValues['adeguamenti'] = '';
        } else if (this.tipo === this.globals.TIPO_TASK_COLLAUDO) {
            formValues['matricola1'] = '';
            formValues['matricola2'] = '';
            formValues['gruppoContinuita'] = false;
            formValues['esito'] = '1';
        } else if (this.tipo === this.globals.TIPO_TASK_GENERICO) {
            formValues['descrizione'] = '';
        } else if (this.tipo === this.globals.TIPO_TASK_ACCESSO_ORDINARIO) {
            formValues['oreFunzionamento'] = '';
        } else if (this.tipo === this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO) {
            formValues['oreFunzionamento'] = '';
            formValues['reperibilita'] = false;
            formValues['anomaliaSegnalata'] = '';
            formValues['anomaliaRiscontrata'] = '';
            formValues['intervento'] = '';
            formValues['nuovaMatricola'] = '';
            formValues['risoltoTelefonicamente'] = false;
        } else if (this.tipo === this.globals.TIPO_TASK_FOLLOWUP) {
            formValues['followupPositivo'] = false;
            formValues['chiarimenti'] = false;
            formValues['ulterioreFollowup'] = false;
        } else if (this.tipo === this.globals.TIPO_TASK_RITIRO_DISPOSITIVO) {
            formValues['oreFunzionamento'] = '';
            formValues['statoDispositivo'] = '';
            formValues['ritiroConsumabili'] = false;
        } else if (this.tipo === this.globals.TIPO_TASK_VERIFICA_ELETTRICA) {
            formValues['strumento'] = 'vpad';
            formValues['costruttore'] = '';
            formValues['serialNumber'] = '';
            formValues['partiApplicate'] = 'B';
            formValues['verificaGenerale'] = false;
            formValues['controlloComponenti'] = false;
            formValues['tensioneL1L2'] = 0;
            formValues['tensioneL1PE'] = 0;
            formValues['tensioneL2PE'] = 0;
            formValues['dispersionePI'] = 0;
            formValues['dispersionePAPI'] = 0;
            formValues['dispersionePN'] = 0;
            formValues['dispersionePAPN'] = 0;
            formValues['dispersionePISuperato'] = false;
            formValues['dispersionePAPISuperato'] = false;
            formValues['dispersionePNSuperato'] = false;
            formValues['dispersionePAPNSuperato'] = false;
            formValues['correnteAssorbita'] = 0;
            formValues['potenzaAssorbita'] = 0;
            formValues['risultatoTest'] = false;
        }
        this.form.setValue(formValues);
    }

    showSubmitButton(): boolean {
        return this.task && this.task.stato == this.taskService.STATO_TASK.SCHEDULATO && 
            (((this.task.tipo === this.globals.TIPO_TASK_VERIFICA_ELETTRICA || (this.task.tipo === this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO && this.form.getRawValue().risoltoTelefonicamente)) && this.firmaTecnico != null) ||
            (this.task.tipo !== this.globals.TIPO_TASK_VERIFICA_ELETTRICA && (!this.signatureNeeded || (this.firmaTecnico != null && (this.firmaAssistito != null || this.firmaCaregiver != null)))));
    } 

    openSignatureDialog(firma: string): void {
        this.isModalSignatureOpen = true;
        this.firma = firma;
    }

    openDdtSignatureDialog(ddtToSign: string): void {
        this.isModalSignatureOpen = true;
        this.ddtToSign = ddtToSign;
    }

    closeSignatureDialog(result: any): void {
        this.isModalSignatureOpen = false;
        if (result) {
            let header: string = "data:image/png;base64,";

            if (this.ddtToSign) {
                this.taskService.getDdtSignatureUrl(this.id).subscribe(r => {
                    this.httpClient.put(this.storageBasePath + r.url, result.file).subscribe(() => {
                        this.taskService.signDdt(this.task.id.toString(), this.ddtToSign).subscribe(() => {
                            this.ddtToSign = undefined;
                            this.taskService.getDocumenti('ddt', this.id).subscribe(ddt => {
                                this.ddt = ddt;
                            });                    
                        });
                    });
                });
            } else {
                if (this.firma === 'tecnico') {
                    this.firmaTecnico = result.file;
                    this.firmaTecnicoImg = header + result.url;
                } else if (this.firma === 'assistito') {
                    this.firmaAssistito = result.file;
                    this.firmaAssistitoImg = header + result.url;
                } else if (this.firma === 'caregiver') {
                    this.firmaCaregiver = result.file;
                    this.firmaCaregiverImg = header + result.url;
                }
            }
        } else {
            this.ddtToSign = undefined;
        }
    }

    onDocumentoChange(event: any) {
        this.documento = event.target.files[0];
    }

    uploadDocumento() {
        this.taskService.aggiungiDocumento('documenti', this.task.id.toString(), this.documento, new TaskDocumentoCommand(this.task.id.toString(), this.documento.name))
            .subscribe((result) => {
                this.documento = null;
                this.fileInputDocumentoTask.nativeElement.value = null;
                this.taskService.getDocumenti('documenti', this.id).subscribe(documenti => {
                    this.documenti = documenti;
                });
            }
        );
    }
    
    onDownloadDocumento(tipo: string, documentId: number) {
        this.taskService.getDocumentDownloadUrl(tipo, documentId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onApriPrescrizione(): void {
        const url = '/prescrizioni/' + this.task.prescrizioneVentilatore.prescrizioneForeignKey;
        const queryParams = { taskId: this.task.id, tipoTask: this.task.tipo, type: 'vent', prescriptionFilter: null };

        queryParams['action'] = this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.VISUALIZZA;
        this.router.navigate([url], { queryParams: queryParams });
    }
}
