import {Component} from '@angular/core';
import {switchMap} from 'rxjs/operators';
import {DatagridRequest} from '../common/datagrid.component';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Observable, of as observableOf} from 'rxjs';
import {QueryResult} from '../../model/query/query-result';
import {ActivatedRoute, Router} from '@angular/router';
import {PrescrizioniBaseComponent} from './prescrizioni-base.component';
import {AuthService} from '../../core/auth.service';
import {MeService} from '../../core/me.service';
import {AziendeCorrentiService} from '../../core/aziende-correnti.service';
import {PrescrizioniService} from '../../core/prescrizioni.service';
import {UtilsService} from '../../core/utils.service';
import {PrescrittoriService} from '../../core/prescrittori.service';
import {PartnersService} from '../../core/partners.service';
import {PrescrizioneActionsService} from '../../core/prescrizione-actions.service';
import {DialogService} from '../../ui/dialog';
import {PrescrizioneVentilazione} from '../../model/query/prescrizione-ventilazione';

@Component({
    selector: 'app-prescrizioni-vent',
    templateUrl: './prescrizioni-vent.component.html',
    styles: []
})
export class PrescrizioniVentComponent extends PrescrizioniBaseComponent {
    constructor(
        protected route: ActivatedRoute,
        public prescrizioniService: PrescrizioniService,
        protected prescrittoriService: PrescrittoriService,
        protected partnersService: PartnersService,
        protected meService: MeService,
        protected aziendeCorrentiService: AziendeCorrentiService,
        protected utilsService: UtilsService,
        public authService: AuthService,
        public prescrizioneActionsService: PrescrizioneActionsService,
        dialog: DialogService,
        router: Router) {
        super(route, prescrizioniService, prescrittoriService, partnersService, meService, aziendeCorrentiService, utilsService, authService, prescrizioneActionsService, dialog, router);
        this.tipo = 'vent';
    }

    protected manageRequest(request: DatagridRequest<this>) {
        request.state.page = {current: 0, from: 0, size: -1, to: -1};
        super.manageRequest(request);
    }
    
    protected fetchData(
        state: ClrDatagridStateInterface,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>
    ): Observable<QueryResult<PrescrizioneVentilazione>> {
        return super.fetchData(state, fields, operators, values).pipe(
            switchMap((result: QueryResult<PrescrizioneVentilazione>) => {
                result.objects = result.objects.map(p => {
                    p['profili'] = p.prescrizioniVentilatore.map(pr => pr.profilo.nome).join('\n');
                    return p;
                });
                return observableOf(result);
            })
        );
    }

}
