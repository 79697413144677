import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PrescrizioniService} from '../../../core/prescrizioni.service';
import {StatoPrescrizione} from '../../../model/stato-prescrizione';
import {PrescrizioneOssigenoLiquido} from '../../../model/query/prescrizione-ossigeno-liquido';
import {PrescrizioneConcentratore} from '../../../model/query/prescrizione-concentratore';
import {PrescrizioneSaturimetro} from '../../../model/query/prescrizione-saturimetro';
import {PrescrizioneVentilazioneDto} from '../../../model/query/prescrizione-ventilazione-dto';
import {Paziente} from '../../../model/query/paziente';
import {datiCliniciFields, diagnosiFields, terapiaFields} from '../fields';

@Component({
    selector: 'app-prescrizione-storico',
    templateUrl: './prescrizione-storico.component.html',
    styleUrls: ['./prescrizione-storico.component.css']
})
export class PrescrizioneStoricoComponent implements OnInit {
    id: string;
    selectedRevision: any;
    showRevisionModal: boolean = false;
    paziente: Paziente;
    revisions: Array<any> = new Array<any>();

    constructor(
        private route: ActivatedRoute,
        public prescrizioniService: PrescrizioniService
    ) {
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.type) {
                    switch (params.type) {
                        case 'o2l':
                            this.prescrizioniService.getPrescrizioneOssigenoLiquido(this.id).subscribe((prescrizione: PrescrizioneOssigenoLiquido) => {
                                this.paziente = prescrizione.paziente;
                                this.prescrizioniService.getRevisioniPrescrizioneOssigenoLiquido(this.id.toString()).subscribe(revisions => this.mapRevisionsData(revisions));
                            });
                            break;
                        case 'o2c':
                            this.prescrizioniService.getPrescrizioneConcentratore(this.id).subscribe((prescrizione: PrescrizioneConcentratore) => {
                                this.paziente = prescrizione.paziente;
                                this.prescrizioniService.getRevisioniPrescrizioneConcentratore(this.id.toString()).subscribe(revisions => this.mapRevisionsData(revisions));
                            });
                            break;
                        case 'sat':
                            this.prescrizioniService.getPrescrizioneSaturimetro(this.id).subscribe((prescrizione: PrescrizioneSaturimetro) => {
                                this.paziente = prescrizione.paziente;
                                this.prescrizioniService.getRevisioniPrescrizioneSaturimetro(this.id.toString()).subscribe(revisions => this.mapRevisionsData(revisions));
                            });
                            break;
                        case 'vent':
                            this.prescrizioniService.getPrescrizioneVentilazione(this.id).subscribe((prescrizioneDto: PrescrizioneVentilazioneDto) => {
                                this.paziente = prescrizioneDto.prescrizione.paziente;
                            this.prescrizioniService.getRevisioniPrescrizioneVentilazione(this.id.toString()).subscribe(revisions => this.mapRevisionsData(revisions));
                            });
                            break;
                        default:
                            break;
                    }
                }
            } else {
                console.log('ERROR');
            }
        });
    }

    private mapRevisionsData(revisions: Array<any>): void {
        this.revisions = new Array<any>();

        for (let i = 0; i < revisions.length; ++i) {
            let revision = revisions[i];
            let r = {};
            let insert: boolean = false;

            r['revisionDate'] = revision.revision.revisionDate;
            r['update'] = false;
            if (revision.type == 'ADD' || revision.modifiedFields.some(f => !f.endsWith('Mod') && f !== 'modificata')) {
                if (revision.type == 'ADD') {
                    r['description'] = 'RICHIESTA ATTIVAZIONE';
                    r['revisionDate'] = revision.entity.dataRicezione || revision.entity.dataPrescrizione;
                    insert = true;
                } else {
                    if (revision.modifiedFields[0] == 'autorizzata') {
                        r['description'] = 'AUTORIZZATA';
                        r['revisionDate'] = revision.entity.dataAutorizzazione;
                        insert = true;
                    } else if (revision.modifiedFields.some(f => f === 'stato')) {
                        r['description'] = this.prescrizioniService.STATO_PRESCRIZIONE[revision.entity.stato].replace('_', ' ');
                        if (revision.entity.stato == StatoPrescrizione.ATTIVATA) {
                            r['dataStato'] = revision.entity.dataRiattivazione || revision.entity.dataAttivazione;
                        } else if (revision.entity.stato == StatoPrescrizione.SOSPESA) {
                            r['dataStato'] = revision.entity.dataSospensione;
                        } else if (revision.entity.stato == StatoPrescrizione.CESSATA) {
                            r['dataStato'] = revision.entity.dataCessazione;
                        } else if (revision.entity.stato == StatoPrescrizione.ANNULLATA) {
                            r['dataStato'] = revision.entity.dataAnnullamento;
                        }
                        insert = true;
                    } else if (revision.modifiedFields.some(f => f === 'dataAssegnazione')) {
                        if (revision.entity.codicePartner) {
                            r['description'] = 'ASSEGNATA A PARTNER';
                        } else {
                            r['description'] = 'DEASSEGNATA DA PARTNER';
                        }
                        r['revisionDate'] = revision.entity.dataAssegnazione;
                        insert = true;
                    } else {
                        r['description'] = '';
                        if (revision.modifiedFields.some(f => f === 'inRinnovo') && revision.entity.inRinnovo) {
                            r['description'] += 'RINNOVATA';
                            r['revisionDate'] = revision.entity.dataPrescrizione;
                            insert = true;
                        }
                        if (revision.modifiedFields.some(f => f === 'modificata') && revision.modifiedFields.some(f => diagnosiFields[f] || diagnosiFields[f] || terapiaFields[f])) {
                            if (r['description']) {
                                r['description'] += '/';
                            }
                            r['description'] += 'MODIFICA ' + [...new Set(revision.modifiedFields.filter(f => f !== 'modificata' && f !== 'autorizzata' && !f.startsWith('data') && !f.endsWith('Mod'))
                                .map(f => datiCliniciFields[f] ? 'DATI CLINICI' : (diagnosiFields[f] ? 'DIAGNOSI' : (terapiaFields[f] ? 'TERAPIA' : ''))).filter(s => s.length > 0))].toString();
                            r['updates'] = revision.modifiedFields.filter(f => f !== 'modificata' && f !== 'autorizzata' && !f.endsWith('Mod'))
                                .map(f => {
                                    return {
                                        "field": diagnosiFields[f] ? 'DIAGNOSI' : datiCliniciFields[f] || terapiaFields[f],
                                        "newValue": diagnosiFields[f] ? (revision.entity[f] ? diagnosiFields[f] : '') : revision.entity[f],
                                        "oldValue": diagnosiFields[f] ? (revisions[i - 1].entity[f] ? diagnosiFields[f] : '') : revisions[i - 1].entity[f],
                                        "date": f.startsWith('data')
                                    };
                                }).filter(o => o.field).sort((o1, o2) => {
                                    return o1.field < o2.field ? -1 : (o1.field === o2.field ? 0 : 1);
                                });
                            r['update'] = true;
                            insert = true;
                        }
                    }
                }
                if (insert) {
                    r['entity'] = revision.entity;
                    this.revisions.push(r);
                }
            }
        }
    }

    onShowRevision(): void {
        this.showRevisionModal = true;
    }
}
