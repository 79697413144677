import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Paziente} from '../model/query/paziente';
import {PazienteUpdateCommand} from '../model/command/paziente-update-command';
import {QueryResult} from '../model/query/query-result';
import {GetAllRequest} from '../model/get-all-request';
import {AbstractCommand} from '../model/command/abstract-command';

@Injectable()
export class PazientiService extends BaseService<Paziente> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('pazienti');
    }

    public getAllCompact(
        page: number,
        size: number,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>,
        sort: string,
        sortType: string
    ): Observable<QueryResult<Paziente>> {
        return this.httpClient.post<QueryResult<Paziente>>(
            this.apiUrl + '/get-all-compact',
            new GetAllRequest(fields, operators, values, sort, sortType, page, size)).pipe(
            catchError(this.handleError(this.apiUrl, new QueryResult<Paziente>()))
        );
    }

    public updateExtended(id: string, command: AbstractCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/extended/' + id, command).pipe(
            catchError(this.handleError(this.apiUrl + ' - update-extended', null))
        );
    }

    public cessa(id: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/cessa/' + id, {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - cessa', null))
        );
    }

    getByCodiceFiscale(codiceFiscale: string): Observable<Paziente> {
        return this.httpClient.get<Paziente>(this.apiUrl + '/by-codice-fiscale/' + codiceFiscale).pipe(
            catchError(this.handleError(this.apiUrl + '- : by codice fiscale', null))
        );
    }

    getNumeroPrescrizioni(pazienteId: number) {
        return this.httpClient.get<Paziente>(this.apiUrl + '/' + pazienteId + '/numero-prescrizioni').pipe(
            catchError(this.handleError(this.apiUrl + '- : numero prescizioni', null))
        );
    }

    getPrescrizioni(pazienteId: number, conScadenza: boolean) {
        const params: HttpParams = new HttpParams().set('conScadenza', conScadenza ? 'true' : 'false');

        return this.httpClient.get<any>(this.apiUrl + '/' + pazienteId + '/get-all-prescrizioni', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl + ' - get-all-prescrizioni', null))
        );
    }

    getElencoPrescrizioni(pazienteId: string) {
        return this.httpClient.get<any>(this.apiUrl + '/' + pazienteId + '/get-elenco-prescrizioni').pipe(
            catchError(this.handleError(this.apiUrl + ' - get-elenco-prescrizioni', null))
        );
    }

    getServiziAttivi(pazienteId: number) {
        return this.httpClient.get<any>(this.apiUrl + '/' + pazienteId + '/get-all-servizi-attivi').pipe(
            catchError(this.handleError(this.apiUrl + ' - get-all-servizi-attivi', null))
        );
    }

    getVersion(codiceFiscale: string, progressivo: number): Observable<number> {
        return this.httpClient.get(this.apiUrl + '/version/' + codiceFiscale + '/' + progressivo, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - version', null))
        );
    }

    public duplicate(id: string, command: PazienteUpdateCommand): Observable<number> {
        return this.httpClient.put(this.apiUrl + '/duplicate' + '/' + id, command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - duplicate', null))
        );
    }
}
