import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {PrescrizioneAction} from '../model/prescrizione-action';
import {StatoPrescrizione} from '../model/stato-prescrizione';

@Injectable({
    providedIn: 'root'
})
export class PrescrizioneActionsService {

    readonly AZIONE_PRESCRIZIONE: typeof PrescrizioneAction = PrescrizioneAction;
    readonly STATO_PRESCRIZIONE: typeof StatoPrescrizione = StatoPrescrizione;

    constructor(private authService: AuthService) {
    }

    public getAuthorizedActions(prescription: any): PrescrizioneAction[] {
        const authorizedActions = [];

        if (!prescription) {
            return authorizedActions;
        }

        const prescriptionStatus = prescription.stato;

        if (prescription.tipo === 'o2l' || prescription.tipo === 'o2c' || prescription.tipo === 'sat' || prescription.tipo === 'vent') {
            switch (prescriptionStatus) {
                case this.STATO_PRESCRIZIONE.RICHIESTA_ATTIVAZIONE: {
                    if (this.authService.isAmministratoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO);
                        if (prescription.autorizzata) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.ATTIVAZIONE);
                        }
                        if (prescription.tipo === 'vent') {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                        }
                    } else if (this.authService.isOperatoreUser()) {
                        if (prescription.autorizzata && !prescription.partnerForeignKey && (!prescription.partner || !prescription.partner.hasOwnProperty('id'))) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.ATTIVAZIONE);
                        }
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (prescription.tipo === 'vent') {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                        }
                    } else if (this.authService.isApprovatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.autorizzata && !prescription.contratto.autoApprovazione) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE);
                        }
                    } else if (this.authService.isAppaltanteUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    } else if (this.authService.isPartnerUser()) {
                        if (prescription.autorizzata && (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id'))) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.ATTIVAZIONE);
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        }
                    } else if (this.authService.isPrescrittoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    } else if (this.authService.isFornitoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                    }
                    return authorizedActions;
                }
                case this.STATO_PRESCRIZIONE.ATTIVATA: {
                    if (this.authService.isAmministratoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.modificata && !prescription.inRinnovo) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE);
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE);
                        }
                        if (prescription.modificata && prescription.autorizzata) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA);
                        }
                        if (prescription.inRinnovo && prescription.autorizzata) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO);
                        }
                        if (prescription.inProroga) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA);
                        }
                        if (!prescription.inRinnovo) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RINNOVO);
                        }
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO);
                    } else if (this.authService.isOperatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.partnerForeignKey && (!prescription.partner || !prescription.partner.hasOwnProperty('id'))) {
                            if (!prescription.modificata && !prescription.inRinnovo) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                                if (prescription.tipo !== 'vent') {
                                    authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE);
                                }
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE);
                            }
                            if (prescription.modificata && prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA);
                            }
                            if (prescription.inRinnovo && prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO);
                            }
                            if (prescription.inProroga) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA);
                            }
                        }
                    } else if (this.authService.isApprovatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.inProroga && prescription.autorizzata && !prescription.contratto.autoApprovazione) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.PROROGA);
                        }
                        if (!prescription.autorizzata && (prescription.modificata || prescription.inRinnovo) && !prescription.contratto.autoApprovazione) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE);
                        }
                    } else if (this.authService.isAppaltanteUser() || this.authService.isFornitoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (prescription.tipo == 'vent' && !prescription.modificata && !prescription.inRinnovo) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                        }
                    } else if (this.authService.isPartnerUser()) {
                        if (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id')) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                            if (!prescription.modificata && !prescription.inRinnovo) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE);
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE);
                            }
                            if (prescription.modificata && prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA);
                            }
                            if (prescription.inRinnovo && prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO);
                            }
                            if (prescription.inProroga) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA);
                            }
                        }
                    } else if (this.authService.isPrescrittoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                        
                        if (!prescription.inRinnovo) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RINNOVO);
                        }
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE);
                    }
                    return authorizedActions;
                }
                case this.STATO_PRESCRIZIONE.RICHIESTA_SOSPENSIONE: {
                    if (this.authService.isAmministratoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO_RICHIESTA);
                        if (prescription.autorizzata) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.SOSPENSIONE);
                        }
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO);
                    } else if (this.authService.isOperatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.partnerForeignKey && (!prescription.partner || !prescription.partner.hasOwnProperty('id'))) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                            if (prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.SOSPENSIONE);
                            }
                        }
                    } else if (this.authService.isApprovatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.autorizzata && !prescription.contratto.autoApprovazione) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE);
                        }
                    } else if (this.authService.isAppaltanteUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    } else if (this.authService.isPartnerUser()) {
                        if (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id')) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                            if (prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.SOSPENSIONE);
                            }
                        }
                    } else if (this.authService.isPrescrittoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    }
                    return authorizedActions;
                }
                case this.STATO_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE: {
                    if (this.authService.isAmministratoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO_RICHIESTA);
                        if (prescription.autorizzata) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RIATTIVAZIONE);
                        }
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO);
                    } else if (this.authService.isOperatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.partnerForeignKey && (!prescription.partner || !prescription.partner.hasOwnProperty('id'))) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                            if (prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RIATTIVAZIONE);
                            }
                        }
                    } else if (this.authService.isApprovatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.autorizzata && !prescription.contratto.autoApprovazione) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE);
                        }
                    } else if (this.authService.isAppaltanteUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    } else if (this.authService.isPartnerUser()) {
                        if (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id')) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                            if (prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RIATTIVAZIONE);
                            }
                        }
                    } else if (this.authService.isPrescrittoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    }
                    return authorizedActions;
                }
                case this.STATO_PRESCRIZIONE.RICHIESTA_CESSAZIONE: {
                    if (this.authService.isAmministratoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO_RICHIESTA);
                        if (prescription.autorizzata) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.CESSAZIONE);
                        }
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO);
                    } else if (this.authService.isOperatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.partnerForeignKey && prescription.autorizzata && (!prescription.partner || !prescription.partner.hasOwnProperty('id'))) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.CESSAZIONE);
                        }
                    } else if (this.authService.isApprovatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.autorizzata && !prescription.contratto.autoApprovazione) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE);
                        }
                    } else if (this.authService.isAppaltanteUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    } else if (this.authService.isPartnerUser()) {
                        if (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id')) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                            if (prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CESSAZIONE);
                            }
                        }
                    } else if (this.authService.isPrescrittoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    }
                    return authorizedActions;
                }
                case this.STATO_PRESCRIZIONE.SOSPESA: {
                    if (this.authService.isAmministratoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.modificata && !prescription.inRinnovo) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE);
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE);
                        }
                        if (prescription.modificata && prescription.autorizzata) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA);
                        }
                        if (prescription.inRinnovo && prescription.autorizzata) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO);
                        }
                        if (prescription.inProroga) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA);
                        }
                        if (!prescription.inRinnovo) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RINNOVO);
                        }
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.ANNULLAMENTO);
                    } else if (this.authService.isOperatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.partnerForeignKey && (!prescription.partner || !prescription.partner.hasOwnProperty('id'))) {
                            if (!prescription.modificata && !prescription.inRinnovo) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE);
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE);
                            }
                            if (prescription.modificata && prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA);
                            }
                            if (prescription.inRinnovo && prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO);
                            }
                            if (prescription.inProroga) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA);
                            }
                        }
                    } else if (this.authService.isApprovatoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (!prescription.inProroga && prescription.autorizzata && !prescription.contratto.autoApprovazione) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.PROROGA);
                        }
                        if (!prescription.autorizzata && (prescription.modificata || prescription.inRinnovo) && !prescription.contratto.autoApprovazione) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE);
                        }
                    } else if (this.authService.isAppaltanteUser() || this.authService.isFornitoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    } else if (this.authService.isPartnerUser()) {
                        if (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id')) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                            if (!prescription.modificata && !prescription.inRinnovo) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE);
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE);
                            }
                            if (prescription.modificata && prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA);
                            }
                            if (prescription.inRinnovo && prescription.autorizzata) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO);
                            }
                            if (prescription.inProroga) {
                                authorizedActions.push(this.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA);
                            }
                        }
                    } else if (this.authService.isPrescrittoreUser()) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                        if (!prescription.inRinnovo) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.RINNOVO);
                        }
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE);
                    }
                    return authorizedActions;
                }
                case this.STATO_PRESCRIZIONE.CESSATA: {
                    if (this.authService.isPartnerUser()) {
                        if (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id')) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        }
                    } else {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        if (this.authService.isAmministratoreUser()) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                        }
                    }
                    return authorizedActions;
                }
                case this.STATO_PRESCRIZIONE.ANNULLATA: {
                    if (this.authService.isPartnerUser()) {
                        if (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id')) {
                            authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                        }
                    } else {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    }
                    return authorizedActions;
                }
                default:
                    return authorizedActions;
            }
        } else {
            if (this.authService.isAmministratoreUser()) {
                authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                if (prescriptionStatus != this.STATO_PRESCRIZIONE.EFFETTUATA) {
                    authorizedActions.push(this.AZIONE_PRESCRIZIONE.EFFETTUAZIONE);
                    authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                }
            } else if (this.authService.isOperatoreUser()) {
                authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                if (prescriptionStatus != this.STATO_PRESCRIZIONE.EFFETTUATA) {
                    authorizedActions.push(this.AZIONE_PRESCRIZIONE.EFFETTUAZIONE);
                    authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                }
            } else if (this.authService.isApprovatoreUser()) {
                authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                if (!prescription.autorizzata && !prescription.contratto.autoApprovazione) {
                    authorizedActions.push(this.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE);
                }
            } else if (this.authService.isAppaltanteUser()) {
                authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
            } else if (this.authService.isPartnerUser()) {
                if (prescription.partnerForeignKey || prescription.partner || prescription.partner.hasOwnProperty('id')) {
                    authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
                    if (prescriptionStatus != this.STATO_PRESCRIZIONE.EFFETTUATA) {
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.EFFETTUAZIONE);
                        authorizedActions.push(this.AZIONE_PRESCRIZIONE.MODIFICA);
                    }
                }
            } else if (this.authService.isPrescrittoreUser()) {
                authorizedActions.push(this.AZIONE_PRESCRIZIONE.VISUALIZZA);
            }
            return authorizedActions;
        }
    }
}
