import {AbstractCommand} from './abstract-command';

export class AttivitaTitolazioneCommand extends AbstractCommand {
    ownerId: number;
    incaricatoId: number;
    dataInserimento: Date;
    dataPrevista: Date;
    dataSchedulata: Date;
    dataCompletamento: Date;
    nonEseguibile: boolean;
    note: string;
    nome: string;
    cognome: string;
    telefono: string;
    indirizzo: string;
    comune: string;
    prescrittoreId: number;

    constructor(
        ownerId: number,
        incaricatoId: number,
        dataInserimento: Date,
        dataPrevista: Date,
        dataSchedulata: Date,
        dataCompletamento: Date,
        nonEseguibile: boolean,
        note: string,
        nome: string,
        cognome: string,
        telefono: string,
        indirizzo: string,
        comune: string,
        prescrittoreId: number
    ) {
        super();
        this.ownerId = ownerId;
        this.incaricatoId = incaricatoId;
        this.dataInserimento = dataInserimento;
        this.dataPrevista = dataPrevista;
        this.dataSchedulata = dataSchedulata;
        this.dataCompletamento = dataCompletamento;
        this.nonEseguibile = nonEseguibile;
        this.note = note;
        this.nome = nome;
        this.cognome = cognome;
        this.telefono = telefono;
        this.indirizzo = indirizzo;
        this.comune = comune;
        this.prescrittoreId = prescrittoreId;
    }
}
