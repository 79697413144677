import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Azienda} from '../model/query/azienda';
import {Comune} from '../model/query/comune';

@Injectable()
export class ComuniService extends BaseService<Azienda> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('comuni');
    }

    getComuni(): Observable<Array<Comune>> {
        return this.httpClient.get<Array<Comune>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get comuni', null))
        );
    }

    getAziendaComuni(aziendaId: number): Observable<Array<Comune>> {
        // const queryParams = {
        //     aziendaId: aziendaId
        // };
        return this.httpClient.get<Array<Comune>>(this.apiUrl + '?aziendaId=' + aziendaId).pipe(
            catchError(this.handleError(this.apiUrl + '- : get comuni', null))
        );
    }
}
